import requestUrls from "../Environment";
import ApiRequest from "./ApiRequest";

// GetAllUser
export async function getAllUsers() {
  try {
    const response = await ApiRequest.get(requestUrls.getAllUsers);
    return Promise.resolve(response.data.item);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Block/UnBlock User
export async function blockUserApi(data) {
  try {
    const response = await ApiRequest.post(requestUrls.blockUser, data);
    return Promise.resolve(response.data.item);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Approve Driver
export async function approveDriverApi(driverId) {
  try {
    const response = await ApiRequest.post(`${requestUrls.approveDriver}${driverId}`);
    return Promise.resolve(response.data.item);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Reject Driver
export async function rejectDriverApi(driverId) {
  try {
    const response = await ApiRequest.post(`${requestUrls.rejectDriver}${driverId}`);
    return Promise.resolve(response.data.item);
  } catch (error) {
    return Promise.reject(error);
  }
}
