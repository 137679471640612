import { Box, Breadcrumbs, Container, Typography, Link } from "@material-ui/core";
import { settings } from "nprogress";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { AllRideList } from "../../components/dashboard/rides_management";
import ChevronRightIcon from "../../icons/ChevronRight";

const RidesHistory = () => {
    const reduxCompletedRides = useSelector((state) => state.Rides.responseCompletedRides);
    return (
        <>
            <Helmet>
                <title>All Ride Details | Hopon Admin</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: "background.default",
                    minHeight: "100%",
                    py: 8,
                }}
            >
                <Container maxWidth={settings.compact ? "xl" : false}>
                    <Typography color="textPrimary" variant="h5">
                        Ride History
                    </Typography>
                    <Breadcrumbs
                        aria-label="breadcrumb"
                        separator={<ChevronRightIcon fontSize="small" />}
                        sx={{ mt: 1 }}
                    >
                        <Link
                            color="textPrimary"
                            component={RouterLink}
                            to="/rider-management"
                            variant="subtitle2"
                        >
                            Rides Management
                        </Link>
                        <Typography color="textSecondary" variant="subtitle2">
                            Rides
                        </Typography>
                    </Breadcrumbs>
                </Container>
                <Box sx={{ mt: 3 }}>
                    <AllRideList completedRides={reduxCompletedRides} />
                </Box>
            </Box>
        </>
    )

};
export default RidesHistory;