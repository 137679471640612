import requestUrls from "../Environment";
import axios from "axios";

export async function LoginApiRequest(email, password) {
  try {
    const response = await axios.post(requestUrls.login, {
      email: email,
      password: password,
    });
    return Promise.resolve(response.data.item);
  } catch (error) {
    return Promise.reject(error);
  }
}
