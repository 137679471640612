export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const PAGES = {
  LIST_RIDER: "riders",
  LIST_DRIVER: "drivers",
  LIST_PAYMENT: "payments",
  LIST_TICKET: "tickets",
  RIDERS_MANAGEMENT : "rider-management",
  RIDES_History: "ride-history",
  RIDER_DETAILS: "riders/:id",
  DRIVER_DETAILS: "drivers/:id",
  PAYMENT_DETAILS: "payments/:id",
  TICKET_DETAILS: "tickets/:id",
  ERROR_401: "authreq",
  ERROR_404: "notFound",
  ERROR_505: "servererror",
  ERROR: "notfound",
};

export const ApiType = {
  userType_User: "Rider",
  userType_Driver: "Driver",
};
