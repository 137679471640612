/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import ChevronRightIcon from "../../icons/ChevronRight";
import useSettings from "../../hooks/useSettings";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RidesListTable } from "../../components/dashboard/rides_management";
import useMounted from "../../hooks/useMounted";
import toast from "react-hot-toast";
import { RideActions } from "../../slices/Rides";
import { getAllRides } from "../../api/RidesApi";
import { useDispatch } from "react-redux";

const RidesManagementDetails = (props) => {
  const params = useParams();
  const mounted = useMounted();
  const { settings } = useSettings();
  const [rides, setRides] = useState([]);
  const dispatch = useDispatch();
  const getRides = async () => {
    try {
      const response = await getAllRides("true", "false");
      dispatch(RideActions.getRides(response));
    } catch (error) {
      toast.error("Something went wrong");
    } finally {}
  };
  
  useEffect(()=> {
    getRides();
  }, [])
  const reduxRides = useSelector((state) => state.Rides.responseRides);
  const Rides = useSelector((state) => state.Rides.ride);
 
  return (
    <>
      <Helmet>
        <title>Ride Details | Hopon Admin</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Ride Details
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/rider-management"
                  variant="subtitle2"
                >
                  Rides Management
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Rides
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <RidesListTable rides={reduxRides} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default RidesManagementDetails;
