import {Rings } from "react-loader-spinner";

const CommonLoader = ()=>{
    return (
    <>
        <div 
            style={{
                display: 'flex',
                width: '100vw',
                zIndex: 9999,
                backgroundColor: '#000000d4',
                justifyContent: 'center',
                alignItems: 'center',
                height: "100vh",
                top: 0,
                left: 0,
                position: 'absolute'}}
        >
           <Rings   color="#3BAE39" height={100} width={100}/>
           {/* <Circles color="#3BAE39" height={100} width={100}/> */}
        </div>
    </>
    )
}

export default CommonLoader