/* eslint-disable no-unused-vars */
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  InputAdornment,
  Table,
  Link,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { cancelRideRequestByAdmin, completeRideRequest, getAllRides } from "../../../api/RidesApi";
import { PAGES } from "../../../constants";
import SearchIcon from "../../../icons/Search";
import { loadingActions } from "../../../slices/Loading";
import { RideActions } from "../../../slices/Rides";
import getInitials from "../../../utils/getInitials";
import ConfirmationModal from "../../common/ConfirmationModal";
import * as helper from "../../common/helper";
import Scrollbar from "../../Scrollbar";
import { Sync } from "@material-ui/icons";

const statusOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Accepted",
    value: "Accepted",
  },
  {
    label: "Ongoing",
    value: "Ongoing",
  },
];

const RidesListTable = (props) => {
  const { rides, ...other } = props;
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState({
    status: null,
  });
  const [ridesId, setRidesId] = useState();
  const [completeConfirm, setCompleteConfirm] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const dispatch = useDispatch();

  const applyFilters = (rides, query, filters) =>
    rides.filter((ride) => {
      let matches = true;

      if (query) {
        const properties = ["userEmail", "userMobile"];
        let containsQuery = false;

        properties.forEach((property) => {
          let value = helper.get_object_value(ride, property, "").toString();
          // console.log(value);
          if (value && value.toLowerCase().includes(query.toLowerCase())) {
            containsQuery = true;
          }
        });

        if (!containsQuery) {
          matches = false;
        }
      }
      if (filters.status && ride.rideRequestStatus !== filters.status) {
        matches = false;
      }
      return matches;
    });

  const applyPagination = (rides, page, limit) =>
    rides.slice(page * limit, page * limit + limit);

  const handleQueryChange = (event) => {
    if(event.target.value.length === 3) { setPage(0) };
    setQuery(event.target.value);
  };
  const handlerComplete = (ridesId) => {
    setRidesId(ridesId);
    setCompleteConfirm(true);
  };
  const handleCompleteCancel = () => {
    setCompleteConfirm(false);
  };

  const handlerCancel = (ridesId) => {
    setRidesId(ridesId);
    setCancelConfirm(true);
  };
  const handleCancelCancel = () => {
    setCancelConfirm(false);
  };

  const StatusComplete = async () => {
    try {
      dispatch(loadingActions.increment());
      const resolve = await completeRideRequest(ridesId);
      dispatch(
        RideActions.setStatus({
          rideId: ridesId,
          rideStatus: resolve.rideRequestStatus
        })
      );
      await getRides();
      toast.success("Ride Completed");
      setCompleteConfirm(false);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      dispatch(loadingActions.decrement());
    }
  };

  const StatusCancel = async () => {
    try {
      dispatch(loadingActions.increment());
      const resolve = await cancelRideRequestByAdmin(ridesId);
      dispatch(
        RideActions.setStatus({
          rideId: ridesId,
          rideStatus: resolve.rideRequestStatus
        })
      );
      await getRides();
      toast.success("Ride Cancelled");
      setCancelConfirm(false);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      dispatch(loadingActions.decrement());
    }
  };

  const getRides = async () => {
    try {
      dispatch(loadingActions.increment());
      const response = await getAllRides("true", "false");
      dispatch(RideActions.getRides(response));
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      dispatch(loadingActions.decrement());
    }
  };

  const handleRideSync = () => {
    getRides();
  };

  const handleStatusChange = (event) => {
    let value = null;

    if (event.target.value !== "all") {
      value = event.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value,
    }));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const filteredRides = applyFilters(rides, query, filters);
  const paginatedRides = applyPagination(filteredRides, page, limit);

  return (
    <>
      <Card {...other}>
        <CardHeader title="Rides" />
        <Divider />
        <Box sx={{ alignItems: "center", display: "flex", flexWrap: "wrap", m: -1, p: 2 }}>
          <Box sx={{ m: 1, maxWidth: "100%", width: 400 }}>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder="Search Rides by Admin"
              value={query}
              variant="outlined"
            />
          </Box>

          <Box
            sx={{ m: 1, maxWidth: "100%", width: 240 }}>
            <TextField
              fullWidth
              label="Status"
              name="status"
              onChange={handleStatusChange}
              select
              SelectProps={{ native: true }}
              value={filters.status || "all"}
              variant="outlined">
              {statusOptions.map((statusOption) => (
                <option key={statusOption.value} value={statusOption.value}>
                  {statusOption.label}
                </option>
              ))}
            </TextField>
          </Box>
          <Box style={{marginLeft : 'auto'}}>
            <Button onClick={handleRideSync}>
              <Sync />
            </Button>
          </Box>
        </Box>
        <Scrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Customer Ride Id</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Customer Email</TableCell>
                <TableCell>Customer Mobile</TableCell>
                <TableCell>Ride Id</TableCell>
                <TableCell>Driver Name</TableCell>
                <TableCell>Driver Email</TableCell>
                <TableCell>Driver Mobile</TableCell>
                <TableCell>Payment Mode</TableCell>
                <TableCell>Payment Status</TableCell>
                <TableCell>Ride Request Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedRides.length === 0 ? (<TableCell align={'center'} colSpan={'100%'}>No Records Found</TableCell>)
                :
                paginatedRides.map((rides) => {
                  return (
                    <TableRow key={rides?.id}>
                      <TableCell>{`${rides?.id}`}</TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <Avatar
                            src={rides?.userProfile}
                            sx={{
                              height: 42,
                              width: 42,
                            }}
                          >
                            {getInitials(rides?.userFirstName)}
                          </Avatar>
                          <Box sx={{ ml: 1, textTransform: "capitalize" }}>
                            <Link
                              color="inherit"
                              component={RouterLink}
                              to={`/${PAGES.RIDER_DETAILS.replace(
                                ":id",
                                rides?.userId
                              )}`}
                              variant="subtitle2"
                            >
                            {`${rides?.userFirstName} ${rides?.userLastName}`}
                            </Link>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>{`${rides?.userEmail}`}</TableCell>
                      <TableCell>{rides?.userMobile}</TableCell>
                      <TableCell>{`${rides?.rideId}`}</TableCell>
                      <TableCell>
                        <Box
                          sx={{ alignItems: "center", display: "flex" }}>
                          <Avatar
                            src={rides?.driverProfile}
                            sx={{ height: 42, width: 42 }}>
                            {getInitials(rides?.driverFirstName)}
                          </Avatar>
                          <Box sx={{ ml: 1, textTransform: "capitalize" }}>
                            <Link
                              color="inherit"
                              component={RouterLink}
                              to={`/${PAGES.DRIVER_DETAILS.replace(
                                ":id",
                                rides.driverUserId
                              )}`}
                              variant="subtitle2"
                            >
                            {`${rides?.driverFirstName} ${rides?.driverLastName}`}
                            </Link>
                          </Box>
                        </Box>
                      </TableCell>
                      
                      <TableCell>{`${rides?.driverEmail}`}</TableCell>
                      <TableCell>{`${rides?.driverMobile}`}</TableCell>
                      <TableCell>{`${rides?.paymentMode}`}</TableCell>
                      <TableCell>{rides?.paymentStatus}</TableCell>
                      <TableCell>{rides?.rideRequestStatus}</TableCell>
                      <TableCell>{rides?.rideRequestStatus === "Ongoing" ?
                        <Button onClick={() => { handlerComplete(rides.id) }}>Complete</Button>
                        :
                        <Button onClick={() => { handlerCancel(rides.id) }}>Cancel</Button>}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
        </Scrollbar>
        <TablePagination
          component="div"
          count={filteredRides.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
      <ConfirmationModal
        open={completeConfirm}
        handleClose={handleCompleteCancel}
        handleConfirm={StatusComplete}
        description={
          "Are you sure you want to complete this ride ?"
        }
        cancleButtonName={"No"}
        confirmButtonName={"Yes"}
      />

      <ConfirmationModal
        open={cancelConfirm}
        handleClose={handleCancelCancel}
        handleConfirm={StatusCancel}
        description={
          "Are you sure you want to cancel this ride ?"
        }
        cancleButtonName={"No"}
        confirmButtonName={"Yes"}
      />
    </>
  );
};

export default RidesListTable;
