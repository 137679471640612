/* eslint-disable no-unused-vars */
import { Avatar, Box, Card, CardHeader, Divider, Link, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField, TablePagination, Button, Tooltip } from "@material-ui/core";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { PAGES } from "../../../constants";
import SearchIcon from "../../../icons/Search";
import getInitials from "../../../utils/getInitials";
import * as helper from "../../common/helper";
import Scrollbar from "../../Scrollbar";
import GHS from "../../../icons/GHS";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { Sync } from "@material-ui/icons";
import { RideActions } from "../../../slices/Rides";
import { getAllRides } from "../../../api/RidesApi";
import Label from "../../Label";
import { loadingActions } from "../../../slices/Loading";

const getStatusLabel = (RideStatus) => {
    const map = {
        Completed: {
            color: "success",
            text: "Completed",
        },
        Pending: {
            color: "error",
            text: "Pending",
        },
        Ongoing: {
            color: "success",
            text: "Ongoing",
        },
        Accepted: {
            color: "success",
            text: "Accepted",
        },
        Cancelled_ByAdmin_AfterMakingPayment: {
            color: "error",
            text: "Cancelled",
        },
        Cancelled_Auto_NoDriverFound: {
            color: "error",
            text: "Cancelled",
        },
        Cancelled_ByUser: {
            color: "error",
            text: "Cancelled",
        },
        Cancelled_Auto_UnPaid: {
            color: "error",
            text: "Cancelled",
        },
        Cancelled_ByUser_BeforeMakingPayment: {
            color: "error",
            text: "Cancelled",
        },
        Cancelled_ByUser_AfterMakingPayment: {
            color: "error",
            text: "Cancelled",
        },
        CancelledRide: {
            color: "error",
            text: "Cancelled",
        },
        Cancelled_By_Driver: {
            color: "error",
            text: "Cancelled",
        },
    };
    const { text, color } = map[RideStatus];

    return <Label color={color}>{text}</Label>;
};
const RideHistoryList = (props) => {
    const { completedRides } = props;
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [query, setQuery] = useState("");
    const [filters, setFilters] = useState({ status: null });
    const applyFilters = (completedRides1, query, filters) =>
    completedRides1.filter((ride) => {
        let matches = true;
        if (query) {
                const properties = ["userEmail", "userMobile", "driverMobile", "driverEmail"];
                let containsQuery = false;

                properties.forEach((property) => {
                    let value = helper.get_object_value(ride, property, "").toString();
                    if (value && value.toLowerCase().includes(query.toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
            }
            if (filters.status && ride.rideRequestStatus !== filters.status) {
                matches = false;
            }
            return matches;
        });
    const applyPagination = (rides, page, limit) => {
        return rides.slice(page * limit, page * limit + limit);
    };
    const handleQueryChange = (event) => {
        if(event.target.value.length === 3) { setPage(0) };
        setQuery(event.target.value);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (event) => {
        setLimit(parseInt(event.target.value, 10));
    };

    const calculatePercentage = (valueAmount) => {
        return (valueAmount - ((valueAmount / 100) / 10))
    };

    const getRideHistory = async () => {
        try {
            dispatch(loadingActions.increment());
            const response = await getAllRides("false", "true");
            dispatch(RideActions.getCompletedRide(response));
        } catch (error) {
            toast.error("Something went wrong");
        } finally {
            dispatch(loadingActions.decrement());
        };
    };

    const handleRideSync = () => {
        getRideHistory();
    };

    const filteredRides = applyFilters(completedRides, query, filters);
    const paginatedRides = applyPagination(filteredRides, page, limit);

    return (
        <>
            <Card >
                <CardHeader title="Completed Rides" />
                <Divider />
                <Box sx={{ alignItems: "center", display: "flex", flexWrap: "wrap", m: -1, p: 2 }}>
                    <Box sx={{ m: 1, maxWidth: "100%", width: 400 }} >
                        <TextField fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>)
                            }}
                            onChange={handleQueryChange}
                            placeholder="Search Rides"
                            value={query}
                            variant="outlined">
                        </TextField>
                    </Box>
                    <Box style={{ marginLeft: 'auto' }}>
                        <Button onClick={handleRideSync}>
                            <Sync />
                        </Button>
                    </Box>
                </Box>
                <Scrollbar>
                    <Box>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Customer Ride Id</TableCell>
                                    <TableCell>Customer Name</TableCell>
                                    <TableCell>Customer Email</TableCell>
                                    <TableCell>Customer Mobile</TableCell>
                                    <TableCell>Tip Amount</TableCell>
                                    <TableCell>Driver Ride Id</TableCell>
                                    <TableCell>Driver Name</TableCell>
                                    <TableCell>Drive Email</TableCell>
                                    <TableCell>Drive Mobile</TableCell>
                                    <TableCell>Payment Mode</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedRides.length === 0 ? (<TableCell align={'center'} colSpan={'100%'}>No Records Found</TableCell>)
                                    : paginatedRides.map((rides) => {
                                        return (
                                            <TableRow key={rides.id}>
                                                <TableCell>{`${rides?.id}`}</TableCell>
                                                <TableCell>
                                                    <Box sx={{ alignItems: "center", display: "flex" }}>
                                                        <Avatar
                                                            src={rides?.userProfile}
                                                            sx={{ height: 42, width: 42 }}>
                                                            {getInitials(rides?.userFirstName)}
                                                        </Avatar>
                                                        <Box sx={{ ml: 1, textTransform: "capitalize" }}>
                                                            <Link
                                                                color="inherit"
                                                                component={RouterLink}
                                                                to={`/${PAGES.RIDER_DETAILS.replace(":id", rides.userId)}`}
                                                                variant="subtitle2">
                                                                {`${rides?.userFirstName} ${rides?.userLastName}`}
                                                            </Link>
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>{`${rides?.userEmail}`}</TableCell>
                                                <TableCell>{`${rides?.userMobile}`}</TableCell>
                                                <TableCell>
                                                    {rides?.tipPaymentStatus === 'success'
                                                        ? <>
                                                            <GHS className='small' />
                                                            {rides?.tipAmount}
                                                        </>
                                                        : '-'
                                                    }
                                                </TableCell>
                                                <TableCell>{`${rides?.rideId}`}</TableCell>
                                                <TableCell>
                                                    <Box
                                                        sx={{ alignItems: "center", display: "flex" }}>
                                                        <Avatar
                                                            src={rides?.driverProfile}
                                                            sx={{ height: 42, width: 42 }}>
                                                            {getInitials(rides?.driverFirstName)}
                                                        </Avatar>
                                                        <Box sx={{ ml: 1, textTransform: "capitalize" }}>
                                                            <Link
                                                                color="inherit"
                                                                component={RouterLink}
                                                                to={`/${PAGES.DRIVER_DETAILS.replace(
                                                                    ":id",
                                                                    rides.driverUserId
                                                                )}`}
                                                                variant="subtitle2">
                                                                {`${rides?.driverFirstName} ${rides?.driverLastName}`}
                                                            </Link>
                                                        </Box>
                                                    </Box>
                                                </TableCell>

                                                <TableCell>{`${rides?.driverEmail}`}</TableCell>
                                                <TableCell>{`${rides?.driverMobile}`}</TableCell>
                                                <TableCell>{`${rides?.paymentMode}`}</TableCell>
                                                <TableCell>
                                                    <Box style={{ display: 'flex', alignItems: "center" }}>
                                                        <GHS className='small' />
                                                        {`${calculatePercentage(rides?.amount)}`}
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title={rides?.rideRequestStatus}>
                                                        <Box>
                                                            {getStatusLabel(rides?.rideRequestStatus)}
                                                        </Box>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </Scrollbar>
                <TablePagination
                    component="div"
                    count={filteredRides.length}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25]}
                />
            </Card>
        </>
    )
};

export default RideHistoryList;
