import { useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Card,
  CardContent,
  Container,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import Logo from "../../images/Chale_Logo.png";
import { Button } from "@material-ui/core";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import { LoginApiRequest } from "../../api/LoginApi";
import { useDispatch } from "react-redux";
import { getUser } from "../../slices/AuthSlice";

const Login = () => {
  const [user, setUser] = useState({ email: "", password: "" });
  const [password, setPassword] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // This function handles input values from user.
  const handleInputChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
    setIsInvalid(false);
  };

  // This function will show password on click event
  const handleShowPassword = () => {
    setPassword((old) => !old);
  };

  // This function verify's user for login
  const handleSubmit = () => {
    if (user.email === null || user.password === null) {
      setIsInvalid(true);
    } else {
      LoginRequest(user.email, user.password);
    }
  };

  const handleKeyUp = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      handleSubmit();
    }
  };

  // Handles Login Request with backend
  const LoginRequest = async (email, password) => {
    try {
      const data = await LoginApiRequest(email, password);
      localStorage.setItem("Token", data.token);
      localStorage.setItem("User", JSON.stringify(data));
      dispatch(getUser(data));
      if (data.token) {
        toast.success("Welcome to Hopon Admin");
        navigate("/");
      }
    } catch (error) {
      toast.error("Invalid Credentials");
    }
  };

  return (
    <>
      <Helmet>
        <title>Login | Hopin Admin</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="sm" sx={{ py: "150px" }}>
          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 3,
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <Box
                  sx={{
                    height: 100,
                    "& > img": {
                      maxHeight: "100%",
                      width: "auto",
                    },
                  }}
                >
                  <img alt="Auth platform" src={Logo} />
                </Box>
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Log in
                  </Typography>
                </div>
              </Box>
              <Box component="form" noValidate autoComplete="off">
                <div>
                  <TextField
                    required
                    style={{ width: "100%" }}
                    id="outlined-required"
                    label="Email"
                    margin="normal"
                    name="email"
                    type="email"
                    value={user.email}
                    onChange={handleInputChange}
                    error={isInvalid}
                    helperText={isInvalid ? "Invalid Email" : ""}
                  />

                  <TextField
                    required
                    style={{ width: "100%" }}
                    id="outlined-required"
                    label="Password"
                    margin="normal"
                    name="password"
                    type={password ? "text" : "password"}
                    value={user.password}
                    onChange={handleInputChange}
                    onKeyUp={handleKeyUp}
                    error={isInvalid}
                    helperText={isInvalid ? "Invalid Password" : ""}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password"
                            onClick={handleShowPassword}
                            edge="end"
                          >
                            {password ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div>
                  <Button
                    variant="contained"
                    size="large"
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      background: "green",
                    }}
                    onClick={handleSubmit}
                  >
                    Login
                  </Button>
                </div>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
