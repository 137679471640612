import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  counter: 0,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    increment(state) {
      state.counter = state.counter + 1;
    },
    decrement(state) {
      state.counter = state.counter - 1;
    },
  },
});

export const { reducer } = loadingSlice;
export const loadingActions = loadingSlice.actions;

export default loadingSlice;
