export const baseURL = "https://api.chaleride.com/api";
// export const baseURL = "http://192.168.29.8/api"

export const requestUrls = {
  login: `${baseURL}/User/Login`,
  getAllUsers: `/User/GetAllUsers`,
  getAllTicket: `/Ticket/GetAllTicket`,
  sendMessage: `/Ticket/SendTicketMessage`,
  resolveTicket: `/Ticket/ResolveTicket/`,
  blockUser: `/User/BlockUnBlockUser/`,
  approveDriver: `/User/ApproveDriver/`,
  rejectDriver: `/User/RejectDriver/`,
  getAllRides: `/Ride/GetAllRides`,
  cancelRideRequestByAdmin: `/Ride/CancelRideRequestByAdmin/`,
  completeRideRequest: `/Ride/CompleteRideRequest/`,
  getAllPayments: `/User/GetPaymentToDrivers/`,
  initiatePayment: `/User/PayToDriver/`,
  getPaymentHistory: `/User/GetPaymentHistoryFromDriverToAdmin`,
};

export default requestUrls;
export const REACT_APP_ENABLE_REDUX_DEV_TOOLS = true;
