import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    responsePayments: [],
    payment:{},
};

const PaymentSlice = createSlice({
    name: "PaymentSlice",
    initialState,
    reducers: {
        getPayments(state, action) {
            state.responsePayments = action.payload;

            let newPayment = {};
            state.responsePayments.forEach((item) => {
                newPayment[item.rideRequestIds] = item;
            });
            state.payment = newPayment;
        },
        setPaymentStatus(state, action) {
            let data = action.payload;
            state.responsePayments = state.responsePayments.map(item => { return item.rideRequestIds === data.rideRequestIds ? data : item; });
        }
    }
});

export const { reducer } = PaymentSlice;
export const PaymentActions = PaymentSlice.actions;
export default PaymentSlice;