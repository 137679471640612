/* eslint-disable no-unused-vars */
import { useRoutes } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import "./i18n";
import RTL from "./components/RTL";
import SettingsDrawer from "./components/SettingsDrawer";
import useAuth from "./hooks/useAuth";
import useScrollReset from "./hooks/useScrollReset";
import useSettings from "./hooks/useSettings";
import routes from "./routes";
import { createCustomTheme } from "./theme";
import { loadingActions } from "./slices/Loading";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllUsers } from "./api/UserApi";
import { UDataActions } from "./slices/UData";
import { TicketActions } from "./slices/TicketSlice";
import { getAllTicket } from "./api/Tickets";
import CommonLoader from "./components/common/CommonLoader";
import { RideActions } from "./slices/Rides";
import { getAllRides } from "./api/RidesApi";
import { getAllPayments } from "./api/PaymentsApi";
import { PaymentActions } from "./slices/Payments";

const App = () => {
  const content = useRoutes(routes);
  const token = localStorage.getItem("Token");
  const dispatch = useDispatch();
  const { settings } = useSettings();
  const isLoading = useSelector((state) => state.loading.counter);
  const auth = useAuth();

  useScrollReset();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  useEffect(() => {
    if (token) {
      const getUsers = async () => {
        try {
          dispatch(loadingActions.increment());
          const response = await getAllUsers();
          dispatch(UDataActions.getUser(response));
        } catch (error) {
          toast.error("Something went wrong");
        } finally {
          dispatch(loadingActions.decrement());
        }
      };
      const getTicket = async () => {
        try {
          dispatch(loadingActions.increment());
          const response = await getAllTicket();
          dispatch(TicketActions.getTicket(response));
        } catch (error) {
          toast.error("Something went wrong");
        } finally {
          dispatch(loadingActions.decrement());
        }
      };
      const getRides = async () => {
        try {
          dispatch(loadingActions.increment());
          const response = await getAllRides("true", "false");
          dispatch(RideActions.getRides(response));
        } catch (error) {
          toast.error("Something went wrong");
        } finally {
          dispatch(loadingActions.decrement());
        }
      };
      const getRideHistory = async () => {
        try {
          dispatch(loadingActions.increment());
          const response = await getAllRides("false", "true");
          dispatch(RideActions.getCompletedRide(response));
        } catch (error) {
          toast.error("Something went wrong");
        } finally {
          dispatch(loadingActions.decrement());
        };
      };

      const getAllPaymentList = async () => {
        try {
          dispatch(loadingActions.increment());
          const response = await getAllPayments();
          dispatch(PaymentActions.getPayments(response))
        } catch (error) {
          toast.error("Something went wrong");
        } finally {
          dispatch(loadingActions.decrement());
        };
      };


      getUsers();
      getTicket();
      getRides();
      getRideHistory();
      getAllPaymentList();
    }
  }, [token, dispatch]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <SettingsDrawer />
        <Toaster position="top-center" />
        <CssBaseline />
        {(isLoading === 0 || true) && content}
        {isLoading > 0 && <CommonLoader />}
      </ThemeProvider>
    </>
  );
};

export default App;
