/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import ConfirmationModal from "../common/ConfirmationModal";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ChartSquareBarIcon from "../../icons/ChartSquareBar";
import UsersIcon from "../../icons/Users";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import { PAGES } from "../../constants";
import toast from "react-hot-toast";
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PaymentsIcon from '@mui/icons-material/Payments';
import logo from "../../images/Chale_Logosquare.png";
import LogoutIcon from '@mui/icons-material/Logout';

const sections = [
  {
    title: "General",
    items: [
      {
        title: "Overview",
        path: "/",
        icon: <ChartSquareBarIcon />,
      },
      {
        title: "Driver Management",
        path: PAGES.LIST_DRIVER,
        icon: <ManageAccountsIcon />,
      },
      {
        title: "Customer Management",
        path: PAGES.LIST_RIDER,
        icon: <UsersIcon />,
      },
      {
        title: "Rides Management",
        path: PAGES.RIDERS_MANAGEMENT,
        icon: <ManageSearchIcon />
      },
      {
        title: "Ride History",
        path: PAGES.RIDES_History,
        icon: <ManageHistoryIcon />
      },
      {
        title: "Payments",
        path: PAGES.LIST_PAYMENT,
        icon: <PaymentsIcon />,
      },
      {
        title: "Ticket Management",
        path: PAGES.LIST_TICKET,
        icon: <ConfirmationNumberIcon />,
      },
    ],
  },
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [logoutConfirmation, setLogoutConfirmation] = useState(false);

  const handleConfirmLogout = () => {
    setLogoutConfirmation(true);
  };

  const handleCancelLogout = () => {
    setLogoutConfirmation(false);
  };

  const handleLogOut = () => {
    localStorage.clear();
    navigate("/login");
    toast.success("Logged out successfully.");
  };
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink
            to="/"
            style={{ display: "inlineBlock", height: "50px", width: "100px" }}
          >
            <img
              src={logo}
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              // alignItems: "center",
              backgroundColor: "background.default",
              borderRadius: 1,
              overflow: "hidden",
              p: 2,
            }}
          >
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Avatar sx={{ width: 50, height: 50, margin: "auto" }} src={logo} />
              {/* <div style={{ width: "50px", height: "50px", margin: "auto" }}>
                <img
                  src={logo}
                  style={{ width: "100%", height: "100%", borderRadius:'50%' }}
                />
              </div> */}
            </Box>

            <Typography
              color="textPrimary"
              style={{ textAlign: "center", margin: 10 }}
            >
              {"Admin"}
            </Typography>
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <IconButton onClick={handleConfirmLogout} sx={{ padding: 0 }}>
                <LogoutIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              height: "calc(100% - 64px) !important",
              top: "64px !Important",
              width: 280,
            },
          }}
          variant="permanent"
        >
          {content}
        </Drawer>
        <ConfirmationModal
          open={logoutConfirmation}
          handleClose={handleCancelLogout}
          handleConfirm={handleLogOut}
          title={"Confirm logout"}
          description={"Are you sure do yo want to logout ?"}
          cancleButtonName={"Cancel"}
          confirmButtonName={"Logout"}
        />
      </>
    );
  }

  return (
    <>
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            width: 280,
          },
        }}
        variant="temporary"
      >
        {content}
      </Drawer>
      <ConfirmationModal
        open={logoutConfirmation}
        handleClose={handleCancelLogout}
        handleConfirm={handleLogOut}
        title={"Confirm logout"}
        description={"Are you sure do yo want to logout ?"}
        cancleButtonName={"Cancel"}
        confirmButtonName={"Logout"}
      />
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
