export const get_object_value = (object, path, defaultVal) => {
    let _path = Array.isArray(path)
      ? path
      : path.split(".").filter((i) => i.length);
    _path = _path.flatMap((x) => {
      if (!(typeof x === "string" || x instanceof String)) return x;
      // if(!/^[\w]+\[[\d]\]$/.test(x)) return x;
      if (!/^[\w]+\[[\w]+\]$/.test(x)) return x;
      return x.replace("[", ".").replace("]", "").split(".");
    });
    if (!_path.length) {
      return object === undefined ? defaultVal : object;
    }
    if (object[_path[0]] === undefined) return defaultVal;
    return get_object_value(object[_path.shift()], _path, defaultVal);
  };