import { createSlice } from "@reduxjs/toolkit";
import { ApiType } from "../constants";

const initialState = {
  responseUsers: [],
  responseDrivers: [],
  users: {},
  drivers: {},
};

const UDataSlice = createSlice({
  name: "UData",
  initialState,
  reducers: {
    getUser(state, action) {
      let data = action.payload;
      state.responseUsers = data;
      let newUser = {};
      let newDriver = {};

      // User Data
      data.forEach((item) => {
        if (item.userType === ApiType.userType_User) {
          newUser[item.id] = item;
        }
        if (item.userType === ApiType.userType_Driver) {
          newDriver[item.id] = item;
        }
      });

      // Driver Data
      state.users = newUser;
      state.drivers = newDriver;
    },

    setDriverBlockStatus(state, action) {
      let data = action.payload;
      state.drivers[data.userId].isActive = data.isBlocked;
    },

    setCustomerBlockStatus(state, action) {
      let data = action.payload;
      state.users[data.userId].isActive = data.isBlocked;
    },

    setApproveStatusDriver(state,action){
      let data = action.payload;
      state.drivers[data.id].driverDetail.approvalStatus = data.status;
    }
  },
});

export const { reducer } = UDataSlice;
export const UDataActions = UDataSlice.actions;

export default UDataSlice;
