import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  responseRides: [],
  ride: {},
  responseCompletedRides:[]
};

const RidesSlice = createSlice({
  name: "RidesSlice",
  initialState,
  reducers: {
    getRides(state, action) {
      state.responseRides = action.payload;

      let newRide = {};
      state.responseRides.forEach((item) => {
        newRide[item.id] = item;
      });
      state.ride = newRide;
    },
    getCompletedRide(state, action){
      state.responseCompletedRides = action.payload
    },
    setStatus(state, action) {
      let data = action.payload;
      state.ride[data.rideId].rideRequestStatus = data.rideStatus;
    }
  },
});

export const { reducer } = RidesSlice;
export const RideActions = RidesSlice.actions;
export default RidesSlice;
