import requestUrls from "../Environment";
import ApiRequest from "./ApiRequest";

// GetAllTickets
export async function getAllTicket() {
  try {
    const response = await ApiRequest.get(requestUrls.getAllTicket);
    return Promise.resolve(response.data.item);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Send Message API
export async function sendMessageApi(body, idNum) {
  try {
    const response = await ApiRequest.post(requestUrls.sendMessage, {
      ticketId: idNum,
      ticketMessage: body,
    });
    return Promise.resolve(response.data.item);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Resolve Ticket API
export async function resolveTicketApi(idNum) {
  try {
    const response = await ApiRequest.post(
      requestUrls.resolveTicket + `${idNum}`
    );
    return Promise.resolve(response.data.item);
  } catch (error) {
    return Promise.reject(error);
  }
}
