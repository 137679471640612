import requestUrls from "../Environment";
import ApiRequest from "./ApiRequest";


// Get All Payments
export async function getAllPayments() {
    try {
        const response = await ApiRequest.get(requestUrls.getAllPayments);
        return Promise.resolve(response.data.item)
    } catch (error) {
        return Promise.reject(error)
    }
};

// Payment to driver
export async function initiatePayment(data) {
    try {
        const response = await ApiRequest.post(requestUrls.initiatePayment, {
            paidTo: data.paidTo,
            amountPaid: data.amountPaid,
            amountToBePaid: data.amountToBePaid,
            rideRequestIds: data.rideRequestIds,
            tipRideRequestIds: data.tipRideRequestIds
        });
        return Promise.resolve(response.data.item)
    } catch (error) {
        return Promise.reject(error);
    }
};

// Payment History
export async function getPaymentHistory(driverId) {
    try {
        const response = await ApiRequest.get(`${requestUrls.getPaymentHistory}?driverId=${driverId}`)
    // const response = await ApiRequest.post(`${requestUrls.approveDriver}${driverId}`);
        return Promise.resolve(response.data.item)
    } catch (error) {
        return Promise.reject(error)
    }
}