/* eslint-disable jsx-a11y/alt-text */
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Box, IconButton, Toolbar } from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import MenuIcon from "../../icons/Menu";
import Hopon from "../../images/Chale_Logo.png"

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: "none",
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink
          to="/"
          style={{
            display: "inlineBlock",
            height: "50px",
            width: "100px",
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              display: {
                lg: "inline-block",
                xs: "none",
              },
            }}
          >
            <img
              src={Hopon}
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </Box>
        </RouterLink>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
