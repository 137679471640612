import React from 'react'

const GHS = () => {
    return (
        <svg
            style={{ height:'0.875rem', paddingTop: '2px', paddingRight:"2px" }}
            xmlns="http://www.w3.org/2000/svg"
            shapeRendering="geometricPrecision"
            textRendering="geometricPrecision"
            imageRendering="optimizeQuality"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="currentColor"
            viewBox="0 0 345 511.44">
            <path
                fillRule="nonzero"
                d="M212.83 48.71c15.5 1.68 29.88 4.81 43.13 9.4 20.89 7.24 38.57 18 53.06 32.27 14.47 14.27 25.02 31.54 31.64 51.81l-65.77 29.79c-4.97-21.1-15.1-36.93-30.41-47.47-9.32-6.43-19.87-10.9-31.65-13.41v288.56c12.41-2.77 23.37-7.83 32.89-15.16 15.31-11.79 25.85-29.47 31.65-53.06l67.63 21.1c-6.62 24-17.37 44.37-32.26 61.12-14.89 16.76-33.1 29.37-54.61 37.85-14.04 5.53-29.14 9.26-45.3 11.19v48.74h-57.08v-49.02c-26.47-3.29-49.84-11.06-70.12-23.32-27.71-16.75-48.91-40.64-63.6-71.67C7.34 336.41 0 299.18 0 255.74c0-43.43 7.66-80.66 22.96-111.69s36.82-54.91 64.53-71.66c20.22-12.23 42.98-19.99 68.26-23.29V0h57.08v48.71zm-57.08 64.55c-9.52 2.89-18.2 7.16-26.06 12.79-16.14 11.59-28.55 28.34-37.23 50.27-8.69 21.92-13.03 48.4-13.03 79.42 0 45.92 8.89 81.81 26.68 107.66 12.07 17.54 28.61 29.13 49.64 34.77V113.26z"
            />
        </svg>
    );
};
export default GHS;
