import requestUrls from "../Environment";
import ApiRequest from "./ApiRequest";


// GetAllRides
export async function getAllRides(ongoing, completed) {
    try {
      const response = await ApiRequest.get(requestUrls.getAllRides+`?Ongoing=${ongoing}&Completed=${completed}`);
      return Promise.resolve(response.data.item);
    } catch (error) {
      return Promise.reject(error);
    }
  }
  

export async function cancelRideRequestByAdmin(RideRequestId) {
  try{
    const response = await ApiRequest.post(`${requestUrls.cancelRideRequestByAdmin}${RideRequestId}`);
    // console.log(response.data.item)
    return Promise.resolve(response.data.item);
  }catch(error){
    return Promise.reject(error);
  }
}

export async function completeRideRequest(RideRequestId) {
  try{
    const response = await ApiRequest.post(`${requestUrls.completeRideRequest}${RideRequestId}`);
    // console.log(response.data.item)
    return Promise.resolve(response.data.item);
  }catch(error){
    return Promise.reject(error);
  }
}