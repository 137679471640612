import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: JSON.parse(localStorage.getItem("User")),
};

const AuthSlice = createSlice({
  name: "AuthSlice",
  initialState,
  reducers: {
    getUser: (state, action) => {
      state.users = action.payload;
    },
  },
});

export const { reducer } = AuthSlice;
export const { getUser } = AuthSlice.actions;

export default AuthSlice;
