import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  responseTicket: [],
  ticket: {},
};

const TicketSlice = createSlice({
  name: "TicketSlice",
  initialState,
  reducers: {
    getTicket(state, action) {
      state.responseTicket = action.payload;

      let newTicket = {};
      state.responseTicket.forEach((item) => {
        newTicket[item.id] = item;
      });
      state.ticket = newTicket;
    },
    sendMessage(state, action) {
      let data = action.payload;
      let ticket = state.ticket;
      ticket[data.id].messages.push(data.newMessage);
      state.ticket = ticket;
    },
    setTicketStatus(state, action) {
      let data = action.payload;
      state.ticket[data.ticketId].ticketStatus = data.status
    },
  },
});

export const { reducer } = TicketSlice;
export const TicketActions = TicketSlice.actions;
export default TicketSlice;
