import { combineReducers } from "@reduxjs/toolkit";
import { reducer as loadingReducer } from "../slices/Loading";
import { reducer as UDataReducer } from "../slices/UData";
import { reducer as AuthReducer } from "../slices/AuthSlice";
import { reducer as TicketReducer } from "../slices/TicketSlice";
import { reducer as RideReducer } from "../slices/Rides";
import { reducer as PaymentReducer } from "../slices/Payments";

const rootReducer = combineReducers({
  loading: loadingReducer,
  UData: UDataReducer,
  auth: AuthReducer,
  ticket: TicketReducer,
  Rides: RideReducer,
  Payments: PaymentReducer,
});

export default rootReducer;
