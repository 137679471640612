import { Suspense, lazy } from "react";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import LoadingScreen from "./components/LoadingScreen";
import { PAGES } from "./constants";
import Login from "./pages/authentication/Login";
import AuthGuard from "./guards/AuthGuard";
import RidesManagementDetails from "./pages/dashboard/RidesManagementDetails";
import AllRidesDetails from "./pages/dashboard/RideHistoryDetails";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboard pages
const Account = Loadable(lazy(() => import("./pages/dashboard/Account")));
const DriverList = Loadable(lazy(() => import("./pages/dashboard/DriverList")));
const DriverDetails = Loadable(
  lazy(() => import("./pages/dashboard/DriverDetails"))
);

const CustomerDetails = Loadable(
  lazy(() => import("./pages/dashboard/CustomerDetails"))
);

const CustomerList = Loadable(
  lazy(() => import("./pages/dashboard/CustomerList"))
);
const PaymentDetails = Loadable(
  lazy(() => import("./pages/dashboard/PaymentDetails"))
);
const PaymentList = Loadable(
  lazy(() => import("./pages/dashboard/PaymentList"))
);

const TicketList = Loadable(lazy(() => import("./pages/dashboard/TicketList")));
const TicketDetails = Loadable(
  lazy(() => import("./pages/dashboard/TicketDetails"))
);
const Overview = Loadable(lazy(() => import("./pages/dashboard/Overview")));

// Error pages
const AuthorizationRequired = Loadable(
  lazy(() => import("./pages/AuthorizationRequired"))
);
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

// Other pages
// const Home = Loadable(lazy(() => import("./pages/Home")));

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Overview />,
      },
      {
        path: "account",
        element: <Account />,
      },
      {
        path: PAGES.LIST_DRIVER,
        element: <DriverList />,
      },
      {
        path: PAGES.DRIVER_DETAILS,
        element: <DriverDetails />,
      },
      {
        path: PAGES.RIDERS_MANAGEMENT,
        element: <RidesManagementDetails />,
      },
      {
        path: PAGES.RIDES_History,
        element: <AllRidesDetails />
      },
      {
        path: PAGES.LIST_RIDER,
        element: <CustomerList />,
      },
      {
        path: PAGES.RIDER_DETAILS,
        element: <CustomerDetails />,
      },
      {
        path: PAGES.LIST_PAYMENT,
        element: <PaymentList />,
      },
      {
        path: PAGES.PAYMENT_DETAILS,
        element: <PaymentDetails />,
      },
      {
        path: PAGES.LIST_TICKET,
        element: <TicketList />,
      },
      {
        path: PAGES.TICKET_DETAILS,
        element: <TicketDetails />,
        children: [
          // {
          //   path: "/",
          //   element: <TicketDetails />,
          // },
          {
            path: "new",
            element: <TicketDetails />,
          },
          {
            path: ":threadKey",
            element: <TicketDetails />,
          },
        ],
      },
    ],
  },

  {
    path: "/login",
    element: <Login />,
  },

  {
    path: "*",
    children: [
      // {
      //   path: "/",
      //   element: <Home />,
      // },
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
